@import './assets/fonts/cerebrisans/stylesheet.css';

:root {
  --primary-color: #531AC5;
  --secondary-color: #1C1C1C;
  --black-color: #010001;
  --error-color: #FF5757;
  --table-row-color: #2F2F2F;
  --waiting-status-color: #FFBB00;
  --active-status-color: #00CE87;
  --inactive-status-color: #FF4B4B;
  --influencer-color: #FF3D6F;
}

html, body, #root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.alert button {
  border: none;
  background: transparent;
  color: inherit;
}

.uploadcare--powered-by {
  display: none;
}

.uploadcare-video-preview {
  min-height: 130px;
  background: #cccccc;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;
  border-radius: 6px;
  text-align: center;
}

.uploadcare-video-preview .fe-play-circle {
  align-self: center;
  font-size: 2.2rem;
}

.uploadcare-video-preview span {
  font-size: 0.8rem;
}
  
.uploadcare--widget {
  position: relative;
  display: flex;
  flex-direction: column;
}

.uploadcare--widget .uploadcare--widget__dragndrop-area {
  padding: 4rem 1rem;
  display: none !important;
}

.uploadcare--widget .uploadcare--widget__button_type_open {
  background: #e3e3e3;
  width: 100%;
  height: 100%;
  padding: 4rem 1rem;
  background-color: #1c1c1c;
  border: 1px dashed #848484;
  border-radius: 0.375rem;
  text-align: center;
  color: white;
  transition: all 0.2s ease-in-out;
  order: -1;
  cursor: pointer;
}

.uploadcare--widget .uploadcare--widget__button_type_remove {
  background: none;
  color: #147cfc;
  border: 0;
}

.uploadcare--widget.uploadcare--dragging .uploadcare--widget__button {
  border: 1px dashed var(--influencer-color);
  background-color: #3c3c3c;
}

.upload-choose-v1 .widget-choose {
  font-size: 1.8rem;
  padding: 1.5rem 1rem 2rem 1rem;
  border: 0.094rem solid #00d97d;
  background: #00d97d;
  width: 100%;
  height: 144px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
}

.upload-choose-v1 .widget-choose .submission-widget-title {
  font-size: 1rem;
}

.upload-choose-v1 .widget-choose .submission-widget-muted {
  font-size: 0.6rem;
}

.upload-choose-v1 .uploadcare--widget .uploadcare--widget__text {
  color: #ff3c6f;
}

.upload-choose-v1 .uploadcare--widget .uploadcare--widget__file-name,
.upload-choose-v1 .uploadcare--widget .uploadcare--widget__file-size {
  display: none;
}

.upload-choose-v1 .uploadcare--widget .uploadcare--widget__button {
  font-size: 1.8rem;
  padding: 1.5rem 1rem 2rem 1rem;
  border: 0.094rem solid #282828;
}

.upload-choose-v1 .uploadcare--widget .uploadcare--widget__button .submission-widget-title {
  font-size: 1rem;
}

.upload-choose-v1 .uploadcare--widget .uploadcare--widget__button .submission-widget-muted {
  color: #b9b9b9;
  font-size: 0.65rem;
  overflow: hidden;
}

.upload-choose-v1 .uploadcare--widget .uploadcare--widget__button:hover {
  border: 0.094rem solid #00d97d;
  background: #00d97d;
}

.upload-choose-v1 .uploadcare--widget .uploadcare--widget__button:hover .submission-widget-muted {
  color: white;
}
  
.simple-upload .uploadcare--widget__button_type_open {
  padding: 5px 1px;
}

.no-file-text .uploadcare--widget__text {
  display: none;
}