@font-face {
    font-family: 'Cerebri Sans';
    src: url('cerebrisans-regular.eot');
    src: url('cerebrisans-regular.eot?#iefix') format('embedded-opentype'), url('cerebrisans-regular.woff') format('woff'), url('cerebrisans-regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Cerebri Sans';
    src: url('cerebrisans-medium.eot');
    src: url('cerebrisans-medium.eot?#iefix') format('embedded-opentype'), url('cerebrisans-medium.woff') format('woff'), url('cerebrisans-medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Cerebri Sans';
    src: url('cerebrisans-semibold.eot');
    src: url('cerebrisans-semibold.eot?#iefix') format('embedded-opentype'), url('cerebrisans-semibold.woff') format('woff'), url('cerebrisans-semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}